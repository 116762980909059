@import './_variables.scss';
@import './_mixins.scss';
@import './_breakpoints.scss';

.programs {
  h2.wp-block-heading {
    margin-bottom: 20px;
    font-family: 'Brandon Grotesque bold';
  }
  .our-work-sub-group {
      flex-direction: column;
      max-width: 1080px;
      margin: 0 auto;
      p {
        font-family: Brandon Grotesque;
      }
  }
  .our-work-title-and-text {
  max-width: 1080px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 80px;

  p {
      font-size: $font-size-xl;
      line-height: 33px;
  }
  }
  .our-work-sub-title {
      margin-top: 2rem;
      font-size: $font-size-4xl;
      font-family: Brandon Grotesque bold;
      @include breakpoint-max-custom(767) {
          margin-top: 0;
          font-size: $font-size-2xl;
          padding-bottom: 15px;
      }
  }
  .our-work-title-and-text {
        @include breakpoint-max-custom(767) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
        img {
            @include breakpoint-max-custom(767) {
            height: auto;
            width: 90vw;
            margin-left: 0rem;
            }
          }
       @include breakpoint-max-custom(1150) {
            padding-left: 2rem;
            padding-right: 2rem;
       }  
       img {
        @include breakpoint-max-custom(1150) {
            height: auto;
            width: 40vw;
            margin-left: 2rem;
        }
       }       

}
    .our-work-sub-group {
        @include breakpoint-max-custom(1150) {
            padding: 30px 20px;
        }
    }
  }
}

//scholarships
.scholarships {
  .media-with-left-caption-section {
    .media-with-left-caption-images {
      .caption-image {
        .wp-block-image {
          .wp-element-caption {
            font-family: Brandon Grotesque;
          }
        }
      }
    }
  }
  .content {
    p {
      font-family: Brandon Grotesque;
    }
    .wp-block-heading {
      font-family: Brandon Grotesque bold;
    }
  }
  h3 {
    margin-bottom: 20px;
  }
  .carousel-blog-section {
    h2 {
      color: $text-color;
      font-size: $font-size-5xl;
      font-weight: $font-weight-medium;
      line-height: 46px;
      margin-bottom: 20px;
    }
  }
  .scholar-testimonials {
    padding: 40px 0 20px;

    .hero-textcenter-section {
      &.hero-textcenter {
        padding: 0;

        p {
          padding: 15px 0;
          font-family: Brandon Grotesque;
        }
      }
    }
  }
}

//talent-show
.talent-show {
  .flockler-embed-content {
      max-width: 1080px !important;
      margin: auto;
      height: 370px !important;
      margin-bottom: 40px;

      .flockler-carousel_v2-item {
        padding: 0px 10px;
        width: 280px !important;

        .flockler-carousel_v2-item__wrapper {
          border: 1px solid $gray-color;
          border-radius: 0px;
        }
      }

      .flockler-carousel_v2-items,
      .flockler-carousel_v2-style--tiles,
      .flickity-enabled,
      .is-draggable {
        outline: none !important;
      }
  }
  .social-media-content {
      display: block;
      width: 100%;
      max-width: 1080px;
      font-size: $font-size-3xl;
      font-weight: $font-weight-normal-bold;
      line-height: 36px;
      margin: 0 auto;
      padding-left: 20px;
      margin-bottom: 0px;
      color: $text-color;
  }
  .carousel-desc-container {
    font-family: Brandon Grotesque;
  }
}

//season-giving
.season-giving {
  .season-of-giving-section {
      max-width: 1080px;
      margin: 80px auto 40px;
      p {
        font-size: $font-size-xl;
        font-family: Brandon Grotesque;
      }
  }
  .season-of-giving-content-media {
      display: flex;
      flex-direction: row;
      padding-left: 0;
      padding-right: 0;
      margin: 40px auto;
      max-width: 1080px;
  }
  .season-of-giving-content {
      margin-top: 2rem;
      margin-left: 0rem;
      margin-right: 0rem;
  }

    .season-of-giving-content-img img {
      margin: 40px 0;
      height: auto;
      width: 100%;
  }
}

@include breakpoint-max('md') {
.season-giving {
  .season-of-giving-section {
    margin: 40px auto 40px;
  }
}
}

//moment-of-joy
.moments-of-joy {
  p {
    font-size: $font-size-xl;
    font-family: Brandon Grotesque;
  }
}

//mentorship
.mentorship {
  .mentorship__page {
    h2 {
      color: $text-color;
      //use important to override the cms css
      font-size: $font-size-lg-md !important;
      font-weight: $font-weight-medium;
      line-height: 46px;
      padding: 0px;
    }
    p {
      font-family: Brandon Grotesque;
      //use important to override the cms css
      font-size: $font-size-base !important;
    }
    .mentorship__text__block {
      .video-details-section {
        .btn-orange a {
          padding: 0px;
        }
      }
    }
  }
}

//community-projects
.community-projects {
  p {
    font-family: Brandon Grotesque;
  }
  .grantsblock {
    font-size: $font-size-5xl;
      font-family: 'Brandon Grotesque bold';
    h2 {
      color: $text-color;
      font-size: $font-size-5xl;
      font-weight: $font-weight-medium;
      line-height: 46px;
      
      @include breakpoint-between($breakpoint-md, $breakpoint-md-lg) {
        font-size: $font-size-2xl;
        line-height: normal;
      }
      @include breakpoint-between($breakpoint-xs, $breakpoint-sm-md) {
        font-size: $font-size-lg-md;
        line-height: normal;
      }
    }
    p {
      font-size: $font-size-xl;
      font-family: Brandon Grotesque;
    }
  }
  .yellowgrantblock {
    align-items: center;
    background: #ffeedf;
    margin-bottom: 40px;
    max-width: 100%;
    padding: 80px 0;
    p {
      font-family: Brandon Grotesque;
    }
  }
}

//events
.events {
  .hero-textcenter {
      h5.wp-block-heading {
          text-align: center;
      }
  }
  .media-with-left-caption-section {
    .media-with-left-caption-images {
      .content {
        p {
          font-family: Brandon Grotesque;
        }
      }
    }
  }
}


@include breakpoint-min('xs') {
  //scholarships
  .scholarships {  
    .carousel-blog-section {
      h2 {
        font-size: $font-size-lg-md;
      }
    }
  }

  //talentshow
  .talent-show {
    .flockler-embed-content {
      width: 100%;
    }
    .flockler-carousel_v2-items .flickity-viewport {
      border: none;
    }
  }

  //community-projects
  .community-projects {
    h2 {
      font-size: $font-size-lg-md;
      padding: 0px !important;
    }
  }
}

@include breakpoint-min('sm') {
  .talent-show {
    .hero-section .wp-block-button {
      margin-left: 12px;
    }
    .flockler-embed-content {
      width: 100%;
      padding: 0px;
    }
  }
}

@include breakpoint-min('md') {
  //scholarships
  .scholarships {  
    .carousel-blog-section {
      h2 {
        font-size: $font-size-2xl;
      }
    }
    .scholar-testimonials {
      .hero-textcenter-section {
        &.hero-textcenter {
          p {
            font-size: $font-size-xl;
          }
        }
      }
    }
  }

  //talentshow
  .talent-show {
    .social-media-content {
      font-size: $font-size-5xl;
      line-height: 46px;
    }
    .hero-section .wp-block-button {
      margin-left: 0px;
      align-items: center;
      display: flex;
      gap: 7.146px;
      height: 35.729px;
      justify-content: center;
      padding: 5.717px 18.579px;
    }
  }

  //mentorship
  .mentorship {
    .mentorship__page {
      p {
        font-size: $font-size-xl !important;
      }
      h2 {
        //use important to override the cms css
        font-size: $font-size-5xl !important;
      }
    }
  }

  //community-projects
  .community-projects {
    .grantsblock {
      h2 {
        font-size: $font-size-2xl;
      }
      p {
        font-size: $font-size-xl;
      }
    }
  }

  //events
  .events {
    .paragraph {
      p {
        font-size: $font-size-xl;
      }
    }
    .media-with-left-caption-section {
      .media-with-left-caption-images {
        .content {
          p {
            font-size: $font-size-xl;
          }
        }
      }
    }
  }

  //programs
  .programs {
    .our-work-sub-group {
      p {
        font-size: $font-size-xl;
      }
    }
  }
}

@include breakpoint-min('lg') {
  //talentshow
  .talent-show {
    .hero-section .wp-block-button {
      margin-left: 0px;
      display: flex;
      height: 47.639px;
      justify-content: center;
      text-align: center;
      width: auto;
    }
  }

  //community-projects
  .community-projects {
    .grantsblock {
      h2 {
        font-size: $font-size-5xl;
      }
    }
  }
}

@include breakpoint-min('xl') {
  //scholarships
  .scholarships {  
    .carousel-blog-section {
      h2 {
        font-size: $font-size-5xl;
      }
    }
    .scholar-testimonials {
      .hero-textcenter-section {
        &.hero-textcenter {
          p {
            font-size: $font-size-xl;
          }
        }
      }
    }
  }

  //talentshow
  .talent-show {
    .hero-section .wp-block-button {
      margin-left: 0px;
      text-align: center;
    }
    .flockler-embed-content {
      width: 100%;
      padding: 0px;
    }
  }
}

@include breakpoint-between(320, 767) {
  .talent-show {
    .button-wrapper {
      background-color: $light-pink-color;
      @include flex-align;
      padding: 10px 0px;
      flex-direction: column;
      gap: 10px;
      align-self: stretch;
      width: 100%;
      .wp-block-button-wrapper {
        @include flex-align;
        height: 35.729px;
        padding: 5.717px 18.579px;
        gap: 7.146px;
        background-color: $color-orange;
      }
      a {
        text-align: center;
          font-style: normal;
          @include typography(11.433px, 500, 'Brandon Grotesque black', 11.433px);
          text-decoration: none;
          color: $text-color;
          font-weight: $font-weight-medium;
      }
    }
    div#sm-request-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }
}

@include breakpoint-max-custom(1150) {
  .programs {
    .our-work-sub-group {
      padding: 30px 20px;
    }
  }
}