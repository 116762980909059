@import './_variables.scss';
@import './_mixins.scss';
@import './breakpoints.scss';

.category.wp-block-buttons,
.category.wp-block-button,
.btn-orange a {
    color: $text-color;
}

.dream-notes {
    .dream-notes-block {
        .content-section {
            .wp-block-button {
                a {
                    padding: 6px 12px;
                    color: $text-color;
                }
            }
        }
    }
}
.btn-orange {
    &.btn {
        background-color: $color-orange-hover;
        border: none;
        &:hover {
            background-color: $color-orange-hover;
            border: none;
        }
        &:focus {
            background-color: $color-orange-hover;
            border: none;
            box-shadow: none;
        }
        &:active {
            background-color: $color-orange-hover;
            border: none;
        }
        &:not(.btn-check):active {
            background-color: $color-orange-hover;
            border: none;
        }
    }
    font-family: 'brandon grotesque black';
    font-size: $font-size-base;
    font-style: normal;
    font-weight: $font-weight-medium;
    @include breakpoint-min('xs') {
        font-size: 11.43px;
    }
    @include breakpoint-min('md') {
        font-size: $font-size-base;;
    }
    a.wp-block-button__link {
        font-family: 'brandon grotesque black';
        font-size: $font-size-base;
        font-style: normal;
        font-weight: $font-weight-medium;
        background-color: transparent !important;
        @include breakpoint-min('xs') {
            font-size: 11.43px;
        }
        @include breakpoint-min('md') {
            font-size: $font-size-base;;
        }
        strong {
            font-weight: $font-weight-medium;
            @include breakpoint-min('xs') {
                font-size: 11.43px;
            }
            @include breakpoint-min('md') {
                font-size: $font-size-base;;
            }
        }
        &:hover {
            background-color: transparent;
        }
        padding: unset;
    }
}
.wp-block-buttons {
    &.btn  {
        padding: 0px;
        .wp-block-button {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;            
            width: 100%;
            margin: 0px;
            a {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 6px 12px;
                height: 100%;                
                width: 100%;
            }
        }
    }
    .wp-block-button {
        .wp-block-button__link strong{
            font-family: 'brandon grotesque black';
            font-size: $font-size-base;
            font-style: normal;
            font-weight: $font-weight-medium;
            @include breakpoint-min('xs') {
                font-size: 11.43px;
            }
            @include breakpoint-min('md') {
                font-size: $font-size-base;;
            }
        }
    }
}

.wp-block-buttons {
    .wp-block-button.primary-button {
        a {
            font-family: 'brandon grotesque black';
            font-size: $font-size-base;
            font-style: normal;
            font-weight: $font-weight-medium;
            @include breakpoint-min('xs') {
                font-size: 11.43px;
            }
            @include breakpoint-min('md') {
                font-size: $font-size-base;
            }
        }
    }
}
.view-load-more {
    font-family: 'brandon grotesque black';
    font-size: $font-size-base;
    font-style: normal;
    font-weight: $font-weight-medium;
    padding: 0px;
    @include breakpoint-min('xs') {
        font-size: 11.43px;
    }
    @include breakpoint-min('md') {
        font-size: $font-size-base;
    }
    a {
        padding: 8px 26px;
    }
}

//fix for community page
.mentorship {
    .wp-block-heading strong {
        font-size: $font-size-5xl;
        font-family: 'Brandon Grotesque bold';
        @include breakpoint-max('sm') {
            font-size: 22px;
        }
    }
}

//Talent product page
.talent-show {
    .season-of-giving-content-img {
        margin-bottom: 20px;
        img {
            margin: 40px 0;
            width: 100%;
        }
    }
    .generic-triptych-section {
        h2 {
            padding: 0px;
        }
    }
}

.ticket-donations {
    .wp-block-buttons {
        .wp-block-button {
            &.btn.btn-orange:active {
                border-color: #ffa435;
                background-color: #ffa435;
            }
            a {
                padding: 12px 24px;
            }
        }
    }
}

.blog {
    .wp-block-buttons {
        .view-load-more {
            padding: 0;
        }
    }
    button.view-load-more {
        padding: 8px 26px;
    }

}
.landing, .events, .scholarships {
    .wp-block-buttons {
        .btn-orange {
            padding: 0;
            a {
                padding: 12px 24px;
                display: flex;
            }
        }
    } 
}
.see-all-stories {
    button.view-load-more {
        padding: 8px 26px;
    }
}
.story-wrapper {
    .story {
        button.view-load-more {
            padding: 8px 26px;
        }  
    }
}
.blog-content {
    button.load-more-story {
        padding: 8px 26px;
    }
}
.talent-show {
    .wp-block-buttons {
        .wp-block-button {
            padding: 0;
            a {
                padding: 8px 26.8px;
            }
            @include breakpoint-max('lg') {
                a {
                    padding: 8px 24.7px;
                }
            }
            @include breakpoint-max('md') {
                a {
                    padding: 5.7px 18.5px;
                }
            }
        }
    }
}
.scholarships {
    .carousel-card {
        button.view-load-more {
            padding: 8px 26px;
        }
    }
}
