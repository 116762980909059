$blue-bg : #161C46;
$color-orange : #FAA946;
$color-orange-hover : #ffa435;
$color-white : #fff;
$color-blue : #005596;
$color-blue-hover : #014b83;
$color-blue-light : #2E3A7A;
$color-red: #ff0000;
$color-black: #000000;
$text-color: #222222;
$color-dot : #D9D9D9;
$light-pink-color : #FFEEDF;
$gray-color : #666;
$disabled-gray: #777777;

// Font Sizes
$font-size-xs: 12px;
$font-size-sm: 14px;
$font-size-base: 16px;
$font-size-md: 18px;
$font-size-lg: 20px;
$font-size-lg-md: 22px;
$font-size-xl: 24px;
$font-size-2xl: 28px;
$font-size-2md: 30px;
$font-size-3xl: 32px;
$font-size-3xl-md: 34px;
$font-size-4xl: 36px;
$font-size-4md: 40px;
$font-size-5xl: 42px;
$font-size-6sm: 60px;

//font weights
$font-weight-light: 300;
$font-weight-light-medium: 390;
$font-weight-normal: 400;
$font-weight-normal-medium: 420;
$font-weight-normal-bold: 450;
$font-weight-medium: 500;
$font-weight-medium-bold: 600;
$font-weight-bold: 700;
$font-weight-strong: 900;

//breakpoints variables
$breakpoint-xs: 320;
$breakpoint-xs-sm: 389; 
$breakpoint-sm: 390;
$breakpoint-sm-md: 767;
$breakpoint-md: 768;
$breakpoint-md-lg: 1023;
$breakpoint-lg: 1024;
$breakpoint-lg-xl: 1439;
$breakpoint-xl: 1440;
$breakpoint-custom-sm: 479;
$breakpoint-custom-sm-md: 480;