@import '../../../style/_variables.scss';
@import '../../../style/_mixins.scss';
@import '../../../style/_breakpoints.scss';

.blog-details-container {
  .blog-details {
    max-width: 1080px;
    padding: 40px 0;
    align-items: center;
    gap: 40px;
    background: var(--gdf-neutral-white, #fff);
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
    margin: 0 auto;

    h2 {
      margin: 0;
      padding: 0;
      padding-bottom: 20px;
      color: var(--gdf-neutral-dark-grey, #222);
      font-size: $font-size-5xl;
      font-style: normal;
      font-weight: $font-weight-medium;
      line-height: 46px;
    }

    p {
      color: var(--gdf-neutral-dark-grey, #222);
      /* GDF Body 1 */
      font-family: Brandon Grotesque;
      font-size: $font-size-xl;
      font-style: normal;
      font-weight: $font-weight-normal-medium;
      line-height: 140%;
    }

    .spacer {
      display: flex;
      height: 2px;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      align-self: stretch;
      background: $color-orange;
      margin: 40px 0px;
    }

    .story-banner {
      width: 100%;
      height: auto;

      img {
        height: 100%;
        width: 100%;
      }
    }

    .details-learn-more {
    }
  }

  .see-all-stories {
    display: flex;
    justify-content: space-between;
    padding: 0px 80px 40px 80px;
    max-width: unset;
    width: 100%;
    align-items: center;

    .left-section {
      display: flex;
      align-items: center;
    }

    .badge-container {
      display: flex;

      .stories-badge-wrapper {
        flex: auto;
      }
    }

    .single-story-badge.selected {
      background-color: orange;
      color: black;
    }
  }
}

@media screen and (max-width: 1200px) {
  .blog-details {
    padding: 40px 20px;
  }

  .see-all-stories .title {
    margin-bottom: 15px;
  }

  .blog-details-container .blog-details .story-banner img {
    height: auto !important;
    width: 100% !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .blog-details-container .see-all-stories .left-section {
    display: grid;
    align-items: center;
  }
}

@media screen and (max-width: 991px) {
  .blog-details-container {
    .blog-details h2 {
      padding: 1rem !important;
    }
    .see-all-stories {
      flex-wrap: wrap;
      padding: 0rem 1rem 1rem 1rem !important;
      // justify-content: center;

      .left-section {
        flex-wrap: wrap;
        padding-bottom: 1rem;

        .badge-container {
          flex-wrap: wrap;

          .single-story-badge {
            padding: 0px 10px 0px 11px;
          }
        }
      }
    }
  }

  .blog-details-container .see-all-stories {
    flex-direction: column;
  }
}

@media screen and (max-width: 760px) {
  .blog-details-container {
    // padding: 1rem;
    .blog-details {
      padding-bottom: 0px;
    }

    .see-all-stories {
      flex-direction: column;
      flex-wrap: wrap;
      padding: 0px !important;
      margin-bottom: 1rem;

      .left-section {
        width: 100%;
        flex-direction: column;
        flex-wrap: wrap;

        .title {
          width: 100%;
          padding-left: 0.6rem;
        }

        .badge-container {
          // display: grid;
          // grid-template-columns: 1fr 1fr;
          width: 100%;
          // margin: 1rem;
          gap: 5px;
          flex-wrap: wrap;
          padding: 10px;
          box-sizing: border-box;

          .stories-badge-wrapper {
            flex: none;
          }

          .single-story-badge {
            padding: 0px 10px 0px 11px;
          }
        }
      }
    }
  }
}